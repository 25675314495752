import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import Img from 'gatsby-image';
import SEO from '../../components/seo';
import TrailTitle from '../../components/TrailTitle';
import { graphql } from 'gatsby';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const delay = keyframes`
  0% {
    opacity: 0;
    height : 0;
  }

  100% {
    opacity: 0;
    height : initial;
  }
`;

const fade = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const DelayFade = styled(Col)`
  animation: ${delay} ${(props) => props.order * 0.5}s,
    ${fade} 0.5s ${(props) => props.order * 0.5}s;
`;

const MottoPage = ({ data, location }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!open);

    return () => {
      setOpen(!open);
    };
  }, []);

  return (
    <>
      <SEO title='Moto dan Budaya' />
      <Img fluid={data.cover.childImageSharp.fluid} />
      <Row
        css={{
          minHeight         : '75vh',
          backgroundImage   : `url(${data.bg.childImageSharp.fixed.src})`,
          backgroundRepeat  : 'no-repeat',
          backgroundPosition: 'top right',
        }}
        className='pt-5'>
        <Col
          sm        = {'5'}
          className = 'd-flex flex-column align-items-center align-content-center'
          css       = {{ justifyContent: 'space-between' }}>
          <TrailTitle open={open} className='pb-2 mb-5'>
            <div>Motto dan</div>
            <div>Budaya</div>
          </TrailTitle>
        </Col>
        <Col className='d-flex flex-column align-items-center align-content-center'>
          <h2 className='font-weight-bold'>Service exellence with smile</h2>
        </Col>
        <Col xs={'12'} sm={{ span: '8', offset: 2 }}>
          <div>
            <h3 className='mb-5 font-weight-bold'>Budaya RSGM IIK BW</h3>
            <Row className='p-1'>
              {data.mottos.edges.map(
                ({ node: { cockpitId, name, logo, description } }, idx) => (
                  <DelayFade sm={'3'} key={cockpitId} order={idx}>
                    <div className='d-flex flex-column justify-content-center align-items-start align-content-center'>
                      <Img
                        fixed     = {logo.value.childImageSharp.fixed}
                        className = 'align-self-center mb-3'
                      />
                      <h5 css={{ fontWeight: 'bold' }}>{name.value}</h5>
                      <p>{description.value}</p>
                    </div>
                  </DelayFade>
                )
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export const query = graphql`
  {
    mottos: allCockpitMottos(filter: { display: { value: { eq: true } } }) {
      edges {
        node {
          cockpitId
          name {
            value
          }
          description {
            value
          }
          logo {
            type
            value {
              childImageSharp {
                fixed(width: 200, toFormat: WEBP) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
    bg: file(name: { eq: "bg-ring" }) {
      childImageSharp {
        fixed(toFormat: WEBP) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover: file(name: { eq: "bg-common" }) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: WEBP) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default MottoPage;
