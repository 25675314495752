import styled from '@emotion/styled';
import React from 'react';
import { a, useTrail } from 'react-spring';

const TrailMain = styled.h1`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-bottom: 6px solid var(--primary);
`;

const TrailText = styled(a.div)`
  position: relative;
  width: 100%;
  will-change: transform, opacity;
  overflow: hidden;
  & > div {
    overflow: hidden;
  }
`;

const TrailTitle = ({ open = true, children, ...props }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2500, friction: 500 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? items.length * 38 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });

  return (
    <TrailMain {...props}>
      <div>
        {trail.map(({ x, height, ...rest }, index) => (
          <TrailText
            key={index}
            style={{
              ...rest,
              transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
            }}>
            <a.div style={{ height }}>{items[index]}</a.div>
          </TrailText>
        ))}
      </div>
    </TrailMain>
  );
};

export default TrailTitle;
